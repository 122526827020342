import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import type { AirportModel } from '@shared/models/airport/airport.model';
import type { MetarResponse } from '@shared/models/airport/cloudflare/wx/metar.model';
import { type Observable, catchError, map, of, shareReplay, switchMap } from 'rxjs';
import { AdbService } from '../airports/adb/adb.service';

@Injectable({
  providedIn: 'root',
})
export class MetarService {
  private readonly httpClient = inject(HttpClient);
  private readonly adbService = inject(AdbService);

  private readonly airportServiceURL: string = environment.garmin.aviation.workerApiHost;

  private readonly _cache = new Map<string, Observable<AirportModel | null>>();

  getSectionDataById(airportId: string): Observable<AirportModel | null> {
    const upperAirportId = airportId.toUpperCase();
    const resp =
      this._cache.get(upperAirportId) ??
      this.adbService.getSectionDataById(upperAirportId).pipe(
        switchMap((airport) => {
          const airport0 = airport?.adbResponse?.AirportEntry.CcAirportInfoList.at(0);
          if (airport0 == null) {
            return of(null);
          }
          return this.getMetar(airport0.latDeg, airport0.lonDeg);
        }),
      );

    if (!this._cache.has(upperAirportId)) {
      this._cache.set(upperAirportId, resp);
    }
    return resp;
  }

  getSectionDataByLatLong(
    stationId: string,
    lat: number,
    long: number,
  ): Observable<AirportModel | null> {
    const upperStationId = stationId?.toUpperCase();
    const resp =
      this._cache.get(upperStationId) ??
      this.adbService.getSectionDataById(upperStationId).pipe(
        switchMap(() => {
          return this.getMetar(lat, long);
        }),
      );

    if (!this._cache.has(upperStationId)) {
      this._cache.set(upperStationId, resp);
    }
    return resp;
  }

  private getMetar(lat: number, lon: number): Observable<AirportModel | null> {
    return this.httpClient
      .get<MetarResponse>(`${this.airportServiceURL}/v1/wx/metar`, {
        withCredentials: true,
        params: { lat: lat, lon: lon },
      })
      .pipe(
        map((response) => ({ metar: response })),
        catchError((_error) => of(null)),
        shareReplay(1),
      );
  }
}
