import { Component, input } from '@angular/core';
import { FlightRule } from '@shared/enums/airports/flight-rules.enum';

export type FlightConditionIconCloudCover = 'Clear' | 'Few' | 'Scattered' | 'Broken' | 'Overcast' | 'Missing' | 'Obscured' | 'Unknown';
type FlightConditionIconCategory = FlightRule | 'MFR' | 'NO WX';
@Component({
  selector: 'pilot-flight-condition-icon',
  standalone: true,
  imports: [],
  templateUrl: './flight-condition-icon.component.html',
  styleUrl: './flight-condition-icon.component.scss'
})
export class FlightConditionIconComponent {
  cloudCover = input<FlightConditionIconCloudCover>('Unknown');
  category = input('NO WX', {
    transform: (c: FlightConditionIconCategory) => {
      if (c === 'MFR') {
        return 'mvfr';
      }
      if (c === 'NO WX') {
        return 'no-wx';
      }
      return c.toLowerCase();
    }
  });
}
