import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import type { AirportModel } from '@shared/models/airport/airport.model';
import type { ChartsResponse } from '@shared/models/airport/cloudflare/airports/chart/charts.model';
import type { AirportSectionFetcher } from '@shared/services/airport/airport.service';
import { type Observable, catchError, map, of, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChartsService implements AirportSectionFetcher {
  private readonly httpClient = inject(HttpClient);

  private readonly airportServiceURL = environment.garmin.aviation.workerApiHost;

  private readonly _cache = new Map<string, Observable<AirportModel | null>>();

  getSectionDataById(airportId: string): Observable<AirportModel | null> {
    const upperAirportId = airportId.toUpperCase();
    const resp =
      this._cache.get(upperAirportId) ??
      this.httpClient
        .get<ChartsResponse>(`${this.airportServiceURL}/v2/airports/${upperAirportId}/charts`, {
          withCredentials: true,
        })
        .pipe(
          map((response) => ({ charts: response })),
          catchError((_error) => of(null)),
          shareReplay(1),
        );

    if (!this._cache.has(upperAirportId)) {
      this._cache.set(upperAirportId, resp);
    }
    return resp;
  }
}
