import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';
import { AirportDataSection, AirportDataSectionPropertyMap } from '@shared/enums/airport-data-section.enum';
import { AirportDataSubset, AirportDataSubsetSectionMap } from '@shared/enums/airport-data-subset.enum';
import { AirportModel } from '@shared/models/airport/airport.model';
import { AirportSearchResponse } from '@shared/models/airport/search/airport-search-response.model';
import { getTimezoneOffset, formatInTimeZone } from 'date-fns-tz';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';
import { AdbService } from '../airports/adb/adb.service';
import { AopaService } from '../airports/aopa/aopa.service';
import { ChartsSupplementService } from '../airports/chart/charts-supplement.service';
import { ChartsService } from '../airports/chart/charts.service';
import { NotamsSummaryService } from '../airports/notam/notams-summary.service';
import { NotamsService } from '../airports/notam/notams.service';
import { FuelStationInfoService } from '../fuel/fuel-station-info.service';
import { AirsigService } from '../wx/airsig.service';
import { ForecastService } from '../wx/forecast.service';
import { MetarService } from '../wx/metar.service';
import { MosService } from '../wx/mos.service';
import { TafService } from '../wx/taf.service';
import { WindsService } from '../wx/winds.service';

export interface AirportSectionFetcher {
  getSectionDataById: (id: string) => Observable<AirportModel | null>;
}

@Injectable({
  providedIn: 'root'
})
export class AirportService {
  private readonly airportSearchUrl = `${environment.garmin.aviation.workerApiHost}/v1/airports/search`;
  private readonly v2AirportSearchUrl = `${environment.garmin.aviation.workerApiHost}/v2/airports/search`;

  private readonly serviceMap = new Map<keyof AirportModel, AirportSectionFetcher>();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly adbService: AdbService,
    private readonly aopaService: AopaService,
    private readonly chartService: ChartsService,
    private readonly chartSupplementService: ChartsSupplementService,
    private readonly notamsService: NotamsService,
    private readonly notamsSummaryService: NotamsSummaryService,
    private readonly fuelstationinfoService: FuelStationInfoService,
    private readonly airsigService: AirsigService,
    private readonly forecastService: ForecastService,
    private readonly metarService: MetarService,
    private readonly mosService: MosService,
    private readonly tafService: TafService,
    private readonly windsService: WindsService
  ) {
    this.serviceMap.set('adbResponse', this.adbService);
    this.serviceMap.set('aopaResponse', this.aopaService);
    this.serviceMap.set('charts', this.chartService);
    this.serviceMap.set('chartSupplements', this.chartSupplementService);
    this.serviceMap.set('notamsResponse', this.notamsService);
    this.serviceMap.set('notamsSummaryResponse', this.notamsSummaryService);
    this.serviceMap.set('fuel', this.fuelstationinfoService);
    this.serviceMap.set('airsigs', this.airsigService);
    this.serviceMap.set('forecastDiscussion', this.forecastService);
    this.serviceMap.set('metar', this.metarService);
    this.serviceMap.set('mos', this.mosService);
    this.serviceMap.set('taf', this.tafService);
    this.serviceMap.set('winds', this.windsService);
  }

  utcCorrectedForDst: boolean = false;
  prevAirportId: string;

  getAirportInfoById(airportId: string, sections: AirportDataSection[] = []): Observable<AirportModel|null> {
    const navigatedToNewAirport = airportId !== this.prevAirportId;
    if (navigatedToNewAirport) {
      this.utcCorrectedForDst = false;
      this.prevAirportId = airportId;
    }

    const upperAirportId = airportId?.toUpperCase();
    const observableObj = sections.map((section) => {
      if (this.serviceMap.get(AirportDataSectionPropertyMap.get(section)!) == null) {
        console.warn(section);
      }
      return this.serviceMap.get(AirportDataSectionPropertyMap.get(section)!)!.getSectionDataById(upperAirportId);
    });
    return forkJoin(observableObj).pipe(map((value) => {
      return value.reduce((acc, val) => {
        if (val?.adbResponse != null) {
          const adbData = val.adbResponse?.AirportEntry.CcAirportInfoList[0];
          if (adbData?.dstIndicator && !this.utcCorrectedForDst) {
            // TEMPORARY SOLUTION TO [AVPILOTWEB-3747]. TO BE REPLACED ONCE A BETTER SOLUTION IS AVAILABLE
            this.getUtcOffsetByLatLon(adbData?.latDeg, adbData?.lonDeg).then(
              (offset) => (adbData.timezoneOffsetHr = offset)
            );
            this.utcCorrectedForDst = true;
          }
        }
        return {...acc, ...val };
      }, {});
    }));
  }

  // TEMPORARY SOLUTION TO [AVPILOTWEB-3747]. TO BE REPLACED ONCE A BETTER SOLUTION IS AVAILABLE
  // Steps to take when removing this solution
  // 1. Delete src/typings.d.ts
  // 2. Remove "node_modules/browser-geo-tz/dist/geotz.js" from the scripts of angular.json
  // 3. Uninstall browser-geo-tz from package.json and make sure that it has been removed from package-lock.json
  async getUtcOffsetByLatLon(lat: number, lon: number): Promise<number> {
    const ianaTimezone = await GeoTZ.find(lat, lon);
    const convertedCurrentDate = new Date(formatInTimeZone(new Date(), ianaTimezone, 'yyyy-MM-dd HH:mm:ssXXX'));
    const toHoursConstant = 3600000;
    return getTimezoneOffset(ianaTimezone, convertedCurrentDate)/toHoursConstant;
  }

  getAirportInfoSubsetById(airportId: string, subset: AirportDataSubset = AirportDataSubset.ALL): Observable<AirportModel | null> {
    return this.getAirportInfoById(airportId, AirportDataSubsetSectionMap.get(subset));
  }

  searchAirports(text: string, limit: number = 20): Observable<AirportSearchResponse | null> {
    if (!isStringNonEmpty(text)) {
      return of(null);
    }
    return this.httpClient
      .get<AirportSearchResponse>(this.airportSearchUrl, {
      withCredentials: true,
      params: {
        text,
        limit,
      }
    })
      .pipe(catchError((_error) => of(null)));
  }

  /**
   * Uses V2 search endpoint which omits weather data from response.
   * {@link AirportSearchResult} category field will automatically be set to 'NO WX'.
   *
   * @param text Search text
   * @returns AirportSearchResponse
   */
  searchAirportsNoWx(text: string, limit: number = 20): Observable<AirportSearchResponse | null> {
    if (!isStringNonEmpty(text)) {
      return of(null);
    }
    return this.httpClient
      .get<AirportSearchResponse>(this.v2AirportSearchUrl, {
      withCredentials: true,
      params: {
        text,
        limit,
      }
    })
      .pipe(catchError((_error) => of(null)));
  }

  searchNearestAirports(lat: number, lon: number, radius: number = 50): Observable<AirportSearchResponse | null> {
    if (lat == null || lon == null) {
      return of(null);
    }
    return this.httpClient
      .get<AirportSearchResponse>(this.airportSearchUrl, {
      withCredentials: true,
      params: {
        lat,
        lon,
        radius
      }
    })
      .pipe(catchError((_error) => of(null)));
  }

  getAirportsByIds(ids: string[]): Observable<AirportSearchResponse | null> {
    if (ids.length <= 0) {
      return of(null);
    }
    return this.httpClient
      .post<AirportSearchResponse>(this.airportSearchUrl, { ids }, {
      withCredentials: true
    })
      .pipe(catchError((_error) => of(null)));
  }
}
