<!-- linthtml-disable -->
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <circle [attr.class]="category()" cx="10" cy="10" r="7.5" style="filter: drop-shadow(0px 0px 2px #00000080);"/>
  </g>
  @switch (cloudCover()) {
    @case ('Clear') {
      <g>
        <path d="m 15.625 10 c 0 3.1066 -2.5184 5.625 -5.625 5.625 c -3.1066 0 -5.625 -2.5184 -5.625 -5.625 c 0 -3.1066 2.5184 -5.625 5.625 -5.625 c 3.1066 0 5.625 2.5184 5.625 5.625 z m -9.6154 0 c 0 2.2039 1.7866 3.9904 3.9904 3.9904 c 2.2039 0 3.9904 -1.7865 3.9904 -3.9904 c 0 -2.2039 -1.7865 -3.9904 -3.9904 -3.9904 c -2.2039 0 -3.9904 1.7866 -3.9904 3.9904 z" fill="#F4F4F4"/>
      </g>
    }
    @case ('Few') {
      <g>
        <path d="m 10 4.375 c 0.7386 0 1.4701 0.1455 2.1526 0.4281 c 0.6825 0.2826 1.3025 0.697 1.8249 1.2194 c 0.5224 0.5224 0.9366 1.1424 1.2194 1.8249 c 0.2826 0.6825 0.4281 1.4139 0.4281 2.1526 l -5.625 0 v -5.625 z" fill="#F4F4F4"/>
      </g>
    }
    @case ('Scattered') {
      <g>
        <path d="m 10 4.375 c 0.7386 0 1.4701 0.1455 2.1526 0.4281 c 0.6825 0.2826 1.3025 0.697 1.8249 1.2194 c 0.5224 0.5224 0.9366 1.1424 1.2194 1.8249 c 0.2826 0.6825 0.4281 1.4139 0.4281 2.1526 c 0 0.7386 -0.1455 1.4701 -0.4281 2.1526 c -0.2828 0.6825 -0.697 1.3025 -1.2194 1.8249 c -0.5222 0.5224 -1.1424 0.9367 -1.8249 1.2194 c -0.6825 0.2826 -1.4139 0.4281 -2.1526 0.4281 l 0 -5.625 v -5.625 z" fill="#F4F4F4"/>
      </g>
    }
    @case ('Broken') {
      <g>
        <path d="m 10 4.375 c 1.1125 0 2.2 0.3299 3.1251 0.948 c 0.925 0.6181 1.646 1.4966 2.0717 2.5244 c 0.4258 1.0279 0.5371 2.1589 0.3201 3.25 c -0.217 1.0911 -0.7527 2.0934 -1.5395 2.88 c -0.7866 0.7867 -1.7889 1.3225 -2.88 1.5395 c -1.0911 0.217 -2.2221 0.1056 -3.25 -0.3201 c -1.0279 -0.4258 -1.9064 -1.1467 -2.5244 -2.0717 c -0.6181 -0.925 -0.948 -2.0125 -0.948 -3.1251 h 5.625 v -5.625 z" fill="#F4F4F4"/>
      </g>
    }
    @case ('Overcast') {
      <g>
        <circle cx="10" cy="10" r="5.625" fill="#F4F4F4"/>
      </g>
    }
    @case ('Missing') {
      <g>
        <text x="10" y="14" text-anchor="middle" font-size="12" fill="#F4F4F4">M</text>
      </g>
    }
    @case ('Obscured') {
      <g>
        <text x="10" y="14" text-anchor="middle" font-size="12" fill="#F4F4F4">X</text>
      </g>
    }
  }
</svg>
