export const CloudType = {
  CL: 'CL',
  CLR: 'CLR',
  SKC: 'SKC',
  FW: 'FW',
  FEW: 'FEW',
  SC: 'SC',
  SCT: 'SCT',
  BK: 'BK',
  BKN: 'BKN',
  OV: 'OV',
  OVC: 'OVC',
} as const;
// eslint-disable-next-line no-redeclare
export type CloudType = (typeof CloudType)[keyof typeof CloudType];

export const CloudTypeDisplayMap: Record<CloudType, string> = {
  [CloudType.CL]: 'Clear',
  [CloudType.CLR]: 'Clear',
  [CloudType.SKC]: 'Sky Clear',
  [CloudType.FW]: 'Few',
  [CloudType.FEW]: 'Few',
  [CloudType.SC]: 'Scattered',
  [CloudType.SCT]: 'Scattered',
  [CloudType.BK]: 'Broken',
  [CloudType.BKN]: 'Broken',
  [CloudType.OV]: 'Overcast',
  [CloudType.OVC]: 'Overcast',
};
