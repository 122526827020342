<fly-card
  class="search-card"
  [padding]="'reduced'">
  <div class="wrapper">

    <div class="airport-info">
      @if (airport().icon == null || airport().icon.length === 0) {
        <fly-icon [icon]="FlightIcons.Airports.ToweredServices"></fly-icon>
      } @else {
        <!-- Unique airport icon -->
        <img alt="runway diagram"
          src="data:image/svg+xml;base64, {{airport().icon}}">
      }

      <div class="airport-data">
        <div class="airport-row">
          <span class="airport-text" >{{ airport().icao }} - {{ airport().name | flyExtendedTitleCase }}</span>
          <div class="wx-conditions">
            <pilot-flight-condition-icon
              [category]="airport().category"
              [cloudCover]="airport().clouds">
            </pilot-flight-condition-icon>
            <span>{{ airport().category }}</span>
          </div>
        </div>

        <span class="location body-3">
          {{ location() }}
        </span>
      </div>
    </div>
  </div>
</fly-card>
